import { useStore } from "@priolo/jon"
import ExportDialog from "components/controls/ExportDialog"
import VerticalPage from "components/controls/page/VerticalPage"
import { FunctionComponent } from "react"
import cycleSo from "stores/cycle"
import draftSo from "stores/draft"
import { loadAll } from "stores/planner/utils/fetch"
import querySo from "stores/route/query"
import { PAGE_ID } from "stores/route/utils/types"
import { useInitFarmPage } from "../useInitFarmPage"
import CyclesTable from "./CyclesTable"
import CyclesFilterDrawer from "./FilterDrawer"
import CycleEditDialog from "./detail/CycleEditDialog"



const CycleTablePag: FunctionComponent = () => {

	// STORE
	useStore(draftSo)
	useStore(cycleSo)

	// HOOKs
	useInitFarmPage(PAGE_ID.FARM_CYCLES, null, async (farm) => {
		await loadAll(farm.id)
		// boh
		querySo.updateQueryUrl()
	})

	// HANDLERS
	const handleExportClose = () => cycleSo.setIsCsvExportOpen(false)

	// RENDER
	return <VerticalPage width={1360}>
		<CyclesTable />
		<CycleEditDialog />
		<CyclesFilterDrawer />
		<ExportDialog
			isOpen={cycleSo.state.isCsvExportOpen}
			onClose={handleExportClose}
			onInitData={cycleSo.getExportData}
		/>
	</VerticalPage>
}

export default CycleTablePag
