import { createStore, StoreCore } from "@priolo/jon";
import cyclesApi from "api/cycles";
import draftSo from "stores/draft";
import farmSo from "stores/farm";
import guSo from "stores/growUnit";
import { LastDateRange } from "stores/planner/utils/fetch";
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import { Cycle } from "types/Cycle";
import { DateNumber, DateRange, Uuid } from "types/global";
import { buildDateRange, dateRangeToUrl, daysDuration, haveValues, normalizeDateRange, urlToDateRange } from "utils";
import { getCycleStatus, getCycleStatusLabel, getLayersInHarvest, getPlannedYield, getYield } from "./utils/cycle";
import { getCycleFiltered } from "./utils/fetch";
import { ExportCSVData } from "components/controls/ExportDialog";
import { getRef } from "utils/humanize";
import dayjs from "dayjs"


const setup = {

	state: {
		/** tutti i CYCLES */
		all: <Cycle[]>[],
		/** il CYCLE selezionato in questo momento */
		select: <Cycle>null,
		isCsvExportOpen: false,
	},

	getters: {
		/** get filtered/sorted CYCLE (for TABLE!) */
		getFiltered: (_: void, store?: CycleStore): Cycle[] => {
			let [txt, dateRangeUrl, growUnitUrl, planned, running, harvested, rejected] = querySo.getSearchUrl([URL_PAR.TEXT, URL_PAR.DATE_SEL, URL_PAR.GROWUNIT_FIL, "planned", "running", "harvested", "rejected"])
			txt = txt?.trim().toLowerCase()
			let dateRange = urlToDateRange(dateRangeUrl)
			if (daysDuration(dateRange) == 0) dateRange = null

			let cycles = getCycleFiltered(store.state.all, txt, dateRange, +growUnitUrl, planned == "false", running == "false", harvested == "false", rejected == "true")

			// [II] da spostare come util a funzione esterna e non dentro uno store
			cycles = querySo.getSorted({
				items: cycles,
				map: {
					status: c => getCycleStatus(c),
					layers: c => getLayersInHarvest(c),
					yield: c => c.yield ?? c.plannedYield,
				},
				sortNameDefault: "seedingDate",
				orderDefault: true,
			})
			// [II]
			return cycles
		},
		/** Recupero da "all" il cycle con l'uuid specificato */
		getCycleByUuid: (cycleUuid: Uuid, store?: CycleStore): Cycle => {
			if (!cycleUuid) return null
			return store.state.all?.find(cycle => cycle.cycleUuid == cycleUuid)
		},
		getIndexByUuid: (cycleUuid: Uuid, store?: CycleStore): number => store.state.all.findIndex(c => c.cycleUuid == cycleUuid),
		getByLotCode: (lotCode: string, store?: CycleStore): Cycle => store.state.all
			.find(c => c.cropLots.some(cl => cl.lotCode == lotCode)),

		/** per l'export in CSV */
		getExportData: (_: void, store?: CycleStore): ExportCSVData => {
			const cycles = store.getFiltered()
			const items = cycles.flatMap(cycle => cycle.cropLots.map(cropLot => [
				// CROP-LOT
				cropLot?.cropName ?? "",
				cropLot?.lotCode ?? "",
				cropLot?.trolleysToHarvest?.toString() ?? "",
				cropLot?.plannedYield?.toString() ?? "",
				cropLot?.yield?.toString() ?? "",
				cropLot?.unitsYield?.toString() ?? "",
				// CyCLE
				getRef(cycle?.cycleUuid),
				farmSo.getGrowUnit(cycle?.growUnitId).name,
				getCycleStatusLabel(cycle),
				dayjs(cycle.seedingDate).format("YYYY-MM-DD"),
				dayjs(cycle.harvestingDate).format("YYYY-MM-DD"),
				getLayersInHarvest(cycle).toString(),
				getPlannedYield(cycle),
				getYield(cycle),
			]))
			const farmName = farmSo.state.select.name
			const range = dateRangeToUrl(LastDateRange)
			return {
				headers: [
					"CROP NAME", "LOT CODE", "TROLLEYS TO HARVEST", "PLANNED YIELD", "CROP YIELD", "UNITS YIELD",
					"CYCLE", "GROW UNIT", "STATUS", "SEEDING DATE", "HARVESTING DATE", "LAYERS", "PLANNED YIELD", "YIELD",
				],
				items,
				fileName: `CROP-LOTS ${farmName} (${range})`
			}
		},
	},

	actions: {
		// [II] ottimizzare con store TASKS
		/** Carico tutti i CYCLEs di una specifica FARM */
		fetch: async (payload: FetchPayload, store?: CycleStore) => {
			// controllo ci siano tutti i dati
			const farmId = payload?.farmId ?? guSo.state.lastFarmId
			const dateRange = normalizeDateRange(payload?.dateRange, LastDateRange)
			if (farmId == null || !haveValues(dateRange)) return

			// a sto punto carico di brutto...
			const { data: cyclesLoad } = await cyclesApi.indexByFarm(farmId, dateRange)
			// se devo aggiungere...
			if (payload.add) {
				draftSo.addCyclesInSessions(cyclesLoad)
				// ...altrimenti sostituisco tutto
			} else {
				draftSo.setCyclesInSessions(cyclesLoad)
			}

			return cyclesLoad
		},
		fetchByGrowUnitSingleDay: async (
			{ growUnitId, date }: { growUnitId: number, date: DateNumber },
			store?: CycleStore
		) => {
			const range = buildDateRange(date)
			const { data } = await cyclesApi.indexByGrowUnit(growUnitId, range)
			store.setAll(data)
		},
	},

	mutators: {
		setAll: (all: Cycle[]) => ({ all }),
		setSelect: (select: Cycle) => ({ select }),
		setIsCsvExportOpen: (isCsvExportOpen: boolean) => ({ isCsvExportOpen }),
	},
}

type FetchPayload = {
	/** id della farm da cui prelevare i dati */
	farmId?: number
	/** non usato */
	growUnitId?: number
	/** il range di date da caricare */
	dateRange?: DateRange
	/** indica che se ne deve fregare che l'aveva gia' caricato e forzare il caricamento */
	force?: boolean
	/** indica che i dati devono essere aggiunti a quelli gia' esistenti */
	add?: boolean
}


export type CycleState = typeof setup.state
export type CycleGetters = typeof setup.getters
export type CycleActions = typeof setup.actions
export type CycleMutators = typeof setup.mutators
export interface CycleStore extends StoreCore<CycleState>, CycleGetters, CycleActions, CycleMutators {
	state: CycleState
}
const cycleSo = createStore(setup) as CycleStore
export default cycleSo