import { FileDownload } from "@mui/icons-material";
import { Box, Button, Switch } from "@mui/material";
import TextField from 'components/controls/fields/TextField';
import Modal, { ModalProps } from "components/controls/Modal";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import MultiSelectorBase2 from "./base/MultiSelectorBase2";
import Paragraph from "./form/Paragraph";



export interface ExportCSVData {
	fileName: string
	headers: string[]
	items: string[][]
}

interface Props extends ModalProps {
	isOpen: boolean
	onInitData: () => ExportCSVData
	onClose: () => void
}

const ExportDialog: FunctionComponent<Partial<Props>> = ({
	isOpen,
	onInitData,
	onClose,
}) => {

	// HOOKs
	const [fileName, setFileName] = useState("")
	const [separator, setSeparator] = useState(",")
	const [haveHeaders, setHaveHeaders] = useState(true)
	const [headers, setHeaders] = useState([] as string[])
	const [headersSelect, setHeadersSelect] = useState<number[]>([])
	const items = useRef<string[][]>([])

	useEffect(() => {
		if (!isOpen) return
		const data = onInitData()
		setFileName(data.fileName ?? "")
		setHeaders(data.headers ?? [])
		setHeadersSelect(Array.from({ length: data.headers.length }, (_, i) => i))
		items.current = data.items ?? []
	}, [isOpen])


	// HANDLER
	const handleOkClick = () => {
		onClose()
		const headerRow = haveHeaders ? [headers.filter((_, i) => headersSelect.includes(i))] : []
		const rows = headerRow.concat(items.current)
		const csvContent = rows.map(row =>
			row.filter((_, i) => headersSelect.includes(i)).join(separator)
		).join('\n')
		const link = document.createElement('a');
		link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
		link.setAttribute('download', `${fileName}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	// RENDER 
	return (
		<Modal
			icon={<FileDownload />}
			title="EXPORT TO CSV"
			maxWidth="sm"
			isOpen={isOpen}
			onClose={onClose}
			actionsRender={<>
				<Box sx={{ flex: "1 1 auto" }} />
				<Button
					onClick={onClose}
				>{"CANCEL"}</Button>
				<Button
					onClick={handleOkClick}
					color="secondary"
				>{"EXPORT"}</Button>
			</>}
		>
			<Paragraph title={"FILE NAME"}>
				<TextField autoFocus sx={{ flex: 1 }}
					placeholder={"Insert a file name"}
					value={fileName}
					onChange={e => setFileName(e.target.value)}
				/>
			</Paragraph>

			<Paragraph title={"SEPARATOR"}>
				<TextField sx={{ flex: 1 }}
					placeholder={"Insert a separator"}
					value={separator}
					onChange={e => setSeparator(e.target.value)}
				/>
			</Paragraph>

			<Paragraph title="WITH HEADER">
				<Switch
					checked={haveHeaders}
					onChange={e => setHaveHeaders(e.target.checked)}
				/>
			</Paragraph>

			<Paragraph sxLabel={{ alignSelf: 'start' }} title={"PARAMS"}>
				<MultiSelectorBase2
					sx={{ bgcolor: "#000000", borderRadius: "10px", maxHeight: "350px" }}
					itemIdsSelect={headersSelect}
					items={headers}
					onChangeItemsSelect={setHeadersSelect}
					fnIdFromItem={(item: any) => headers.indexOf(item)}
				/>
			</Paragraph>

		</Modal>
	)
}

export default ExportDialog



